var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userAuthor)?_c('v-card',{attrs:{"color":"#EEEEEE"}},[_c('v-card-title',[_vm._v(" Создано: ")]),_c('v-expansion-panels',{attrs:{"color":"#EEEEEE"}},_vm._l((_vm.listProducts),function(ref){
var title = ref.title;
var data = ref.data;
var path = ref.path;
return _c('v-expansion-panel',{key:title},[(data.length)?_c('v-expansion-panel-header',{attrs:{"color":"#EEEEEE"}},[_c('span',{staticClass:"text-decoration-underline"},[_vm._v(" "+_vm._s(title))])]):_vm._e(),_c('v-expansion-panel-content',{attrs:{"color":"#EEEEEE"}},_vm._l((data),function(ref){
var name = ref.name;
var id = ref.id;
return _c('router-link',{key:id,staticClass:"cursorPointer body-2",attrs:{"tag":"span","to":("" + path + id)}},[_vm._v(_vm._s(name)+", ")])}),1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card v-if="userAuthor" color="#EEEEEE">
    <v-card-title>
      Создано:
    </v-card-title>
    <v-expansion-panels color="#EEEEEE">
      <v-expansion-panel v-for="{ title, data, path } in listProducts" :key="title">
        <v-expansion-panel-header v-if="data.length" color="#EEEEEE">
          <span class="text-decoration-underline"> {{ title }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#EEEEEE">
          <router-link
            v-for="{ name, id } in data"
            :key="id"
            tag="span"
            class="cursorPointer body-2"
            :to="`${path}${id}`"
            >{{ name }},
          </router-link>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'AuthorProductList',
  computed: {
    ...mapState({
      user: state => state.user.user,
      listAuthors: state => state.shared.listAuthors,
      listStudios: state => state.studios.listStudios,
      listStudiosGroup: state => state.studios.listStudiosGroup,
      listCalendars: state => state.studios.listCalendars,
      artTherapyList: state => state.artTherapy.artTherapyList
    }),
    ...mapGetters([
      'filterDraftListStudios',
      'filterDraftListCalendars',
      'filterDraftArtTherapyList',
      'userAuthor'
    ]),
    listProducts() {
      let list = []
      list.push({
        title: 'Студии:',
        data: this.filterProducts(this.filterDraftListStudios),
        path: '/studios/home/'
      })
      list.push({
        title: 'Студии в группе:',
        data: this.filterProducts(this.listStudiosGroup),
        path: '/studios/group/'
      })
      list.push({
        title: 'Нейрографика:',
        data: this.filterProducts(this.filterDraftArtTherapyList),
        path: '/artTherapyDescription/'
      })
      list.push({
        title: 'Календарь:',
        data: this.filterProducts(this.filterDraftListCalendars),
        path: '/calendar/'
      })
      return list
    }
  },
  created() {
    if (!this.listAuthors.length) this.$store.dispatch('getListAuthors')
  },
  methods: {
    filterProducts(list) {
      if (this.userAuthor) {
        let localList = []
        this.userAuthor.products.forEach(product => {
          let element = list.find(item => item.id === product)
          if (element) {
            localList.push({
              name: element.studioName,
              id: element.id
            })
          }
        })
        return localList
      }
    }
  }
}
</script>

<style scoped></style>
